import PopupContainerComponent from '@wix/thunderbolt-elements/src/components/PopupContainer/viewer/PopupContainer';


const PopupContainer = {
  component: PopupContainerComponent
};


export const components = {
  ['PopupContainer']: PopupContainer
};


// temporary export
export const version = "1.0.0"
