import * as React from 'react';
import { IPopupContainerProps } from '../PopupContainer.types';
import FillLayers from '../../FillLayers/viewer/FillLayers';

const PopupContainer: React.FC<IPopupContainerProps> = props => {
  const { id, fillLayers, children } = props;
  const sdkEventHandlers = {
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
  };

  return (
    <div id={id} {...sdkEventHandlers}>
      <FillLayers {...fillLayers} />
      {children()}
    </div>
  );
};

export default PopupContainer;
